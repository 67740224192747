<template>
    <div>
        <span>{{stStr}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <v-progress-circular
                indeterminate
                color="blue"
        ></v-progress-circular>
    </div>
</template>

<script>
    let interver = 5000
    export default {
        name: "BackupProcess",
        data(){
            return {
                isSuccess : false,
            }
        },
        props : {
            item: Object,
            snapshot: Boolean
        },
        computed:{
            stStr(){
                if(this.item.status === 'Backuping'){
                    return this.snapshot?'Taking snapshot':'Backing up'
                }else{
                    return this.item.status
                }
            }
        },
        methods: {
            getProgress(){
                return this.$http.get(`/backups/${this.item.uuid}/`)
            },
            isPending (status){
                return  ['Deleting','Backuping','Restoring'].indexOf(status) !== -1
            },
            tick(){
                if(this.isSuccess){
                    return
                }
                this.getProgress().then((res) => {
                    if(this.isPending(res.status)){
                       this.timer =  setTimeout(() => {
                           this.tick()
                       },interver)
                    }else{
                        this.isSuccess = true
                        this.$emit('success')
                        clearTimeout(this.timer)
                    }
                }).catch( () => {
                    this.timer =  setTimeout(() => {
                        this.tick()
                    },interver)
                })
            }
        },
        created(){
            this.tick()
        },
        beforeDestroy(){
            this.isSuccess = true
            clearTimeout(this.timer)
        }
    }
</script>

<style lang="scss">

</style>
