var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "panel-wrapper panel-overview pa-0",
      attrs: { "grid-list-lg": "", fluid: "" }
    },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-flex",
            [
              _c(
                "TabCard",
                { attrs: { title: "Snapshots" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { column: "" } },
                    [
                      _c("v-flex", [
                        _c(
                          "p",
                          {
                            staticClass: "fs-3 font-weight-regular ",
                            staticStyle: {
                              "font-size": "14px",
                              color: "#666",
                              margin: "0"
                            }
                          },
                          [
                            _vm._v(
                              "\n                                A snapshot is a filesystem-level backup. It contains a complete copy of the " +
                                _vm._s(_vm.appInfo.product_name) +
                                " deployment.\n                            "
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { attrs: { column: "" } },
                    [
                      _c(
                        "v-flex",
                        [
                          _c(
                            "v-data-table",
                            {
                              staticClass: "elevation-1",
                              attrs: {
                                headers: _vm.headers,
                                items: _vm.list,
                                loading: _vm.loading,
                                "hide-actions": ""
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "items",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "td",
                                        { staticClass: "text-xs-left" },
                                        [_vm._v(_vm._s(item.name))]
                                      ),
                                      _vm.isPending(item.status)
                                        ? [
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "text-xs-right px-2",
                                                attrs: { colspan: "6" }
                                              },
                                              [
                                                _c("BackupProcess", {
                                                  attrs: {
                                                    item: item,
                                                    snapshot: ""
                                                  },
                                                  on: { success: _vm.getList }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        : [
                                            _c(
                                              "td",
                                              { staticClass: "text-xs-right" },
                                              [_vm._v(_vm._s(item.type))]
                                            ),
                                            _c(
                                              "td",
                                              { staticClass: "text-xs-right" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.formatDate(item.created)
                                                  )
                                                )
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              { staticClass: "text-xs-right" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    item.backup_method ===
                                                      "Full"
                                                      ? "Full"
                                                      : "Incremental"
                                                  )
                                                )
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              { staticClass: "text-xs-right" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    item.file_size
                                                      ? item.file_size + "MB"
                                                      : "--"
                                                  )
                                                )
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              { staticClass: "text-xs-right" },
                                              [_vm._v(_vm._s(item.status))]
                                            ),
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "text-xs-center px-0"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      display: "inline-block"
                                                    }
                                                  },
                                                  [
                                                    item.export_status ===
                                                    "Success"
                                                      ? [
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                top: ""
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var on =
                                                                        ref.on
                                                                      return [
                                                                        _c(
                                                                          "v-btn",
                                                                          _vm._g(
                                                                            {
                                                                              attrs: {
                                                                                flat:
                                                                                  "",
                                                                                icon:
                                                                                  "",
                                                                                color:
                                                                                  "primary"
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.downloadData(
                                                                                    item
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            on
                                                                          ),
                                                                          [
                                                                            _c(
                                                                              "v-icon",
                                                                              [
                                                                                _vm._v(
                                                                                  "cloud_download"
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "Download"
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ]
                                                      : _vm._e(),
                                                    item.export_status ===
                                                    "Processing"
                                                      ? [
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                top: ""
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var on =
                                                                        ref.on
                                                                      return [
                                                                        _c(
                                                                          "v-progress-circular",
                                                                          _vm._g(
                                                                            {
                                                                              attrs: {
                                                                                indeterminate:
                                                                                  "",
                                                                                width:
                                                                                  "2",
                                                                                size:
                                                                                  "16",
                                                                                color:
                                                                                  "primary"
                                                                              }
                                                                            },
                                                                            on
                                                                          )
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "Exporting"
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ]
                                                      : _vm._e()
                                                  ],
                                                  2
                                                )
                                              ]
                                            )
                                          ],
                                      _c(
                                        "td",
                                        { staticClass: "text-xs-right" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                display: "inline-block",
                                                width: "44px"
                                              },
                                              attrs: {
                                                title: "Manage your backup."
                                              }
                                            },
                                            [
                                              !_vm.isPending(item.status)
                                                ? _c(
                                                    "v-menu",
                                                    {
                                                      attrs: {
                                                        transition:
                                                          "slide-x-transition"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  _vm._g(
                                                                    {
                                                                      attrs: {
                                                                        icon:
                                                                          "",
                                                                        small:
                                                                          ""
                                                                      }
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          small:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "more_vert"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _c(
                                                        "v-list",
                                                        [
                                                          _c(
                                                            "v-list-tile",
                                                            {
                                                              attrs: {
                                                                disabled:
                                                                  [
                                                                    "Success",
                                                                    "Restore Failure"
                                                                  ].indexOf(
                                                                    item.status
                                                                  ) === -1
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.$refs.restoring.open(
                                                                    {
                                                                      item: item
                                                                    }
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-tile-title",
                                                                [
                                                                  _vm._v(
                                                                    "Restore"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          item.status ===
                                                          "Success"
                                                            ? _c(
                                                                "v-list-tile",
                                                                {
                                                                  attrs: {
                                                                    disabled:
                                                                      [
                                                                        "Success",
                                                                        "Processing"
                                                                      ].indexOf(
                                                                        item.export_status
                                                                      ) !== -1
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.$refs.exporting.open(
                                                                        {
                                                                          item: item
                                                                        }
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-tile-title",
                                                                    [
                                                                      _vm._v(
                                                                        "Export"
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          item.type === "Manual"
                                                            ? _c(
                                                                "v-list-tile",
                                                                {
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.$refs.del.open(
                                                                        {
                                                                          item: item
                                                                        }
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-tile-title",
                                                                    [
                                                                      _vm._v(
                                                                        "Delete"
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "no-data",
                                  fn: function() {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "min-height": "192px",
                                            position: "relative"
                                          }
                                        },
                                        [
                                          _c("Spin", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.loading,
                                                expression: "loading"
                                              }
                                            ]
                                          }),
                                          _c("Empty", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.loading,
                                                expression: "!loading"
                                              }
                                            ]
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ])
                            },
                            [
                              _c("v-progress-linear", {
                                attrs: { color: "blue", indeterminate: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "progress",
                                    fn: function() {
                                      return undefined
                                    },
                                    proxy: true
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticStyle: { padding: "30px 0 0" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "text-none",
                              attrs: {
                                title:
                                  "Take a system-level backup of your application manually.",
                                color: "primary"
                              },
                              on: {
                                click: function($event) {
                                  _vm.$refs.addStore.open({
                                    name: _vm.createDefaultName()
                                  })
                                }
                              }
                            },
                            [_vm._v("Take Snapshot")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "Confirm",
                    {
                      ref: "del",
                      on: { confirm: _vm.deleteBackup },
                      scopedSlots: _vm._u([
                        {
                          key: "content",
                          fn: function(ref) {
                            var options = ref.options
                            return [
                              options.item && options.item.name
                                ? _c("span", [
                                    _vm._v(
                                      "\n\t\t\t\t\t\tBackup\n\t\t\t\t\t\t"
                                    ),
                                    _c("b", [
                                      _vm._v(_vm._s(options.item.name))
                                    ]),
                                    _vm._v(
                                      "\n\t\t\t\t\t\twill be deleted permanently. Are sure you want to delete it?\n\t\t\t\t\t"
                                    )
                                  ])
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v("Delete Backup")
                      ])
                    ]
                  ),
                  _c(
                    "AddStore",
                    { ref: "addStore", on: { confirm: _vm.addStore } },
                    [_c("span", [_vm._v("Backup")])]
                  ),
                  _c(
                    "Confirm",
                    {
                      ref: "restoring",
                      attrs: { "max-width": "600px" },
                      on: { confirm: _vm.reStoring },
                      scopedSlots: _vm._u([
                        {
                          key: "content",
                          fn: function(ref) {
                            var options = ref.options
                            return [
                              options.item && options.item.name
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#666" } },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\tSnapshot\n\t\t\t\t\t\t\t"
                                          ),
                                          _c(
                                            "span",
                                            {
                                              style: {
                                                color:
                                                  _vm.$vuetify.theme.primary
                                              }
                                            },
                                            [_vm._v(_vm._s(options.item.name))]
                                          ),
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\twill be restored to this " +
                                              _vm._s(_vm.appInfo.product_name) +
                                              " cluster. Its dataset and configuration will be restored to the point when the snapshot was taken.\n\t\t\t\t\t\t"
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-subheader",
                                        { staticClass: "panel-title" },
                                        [
                                          _vm._v(
                                            "This operation is undoable. Do you want to continue ?"
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v("Restore Snapshot")
                      ])
                    ]
                  ),
                  _c(
                    "Confirm",
                    {
                      ref: "exporting",
                      attrs: { "max-width": "600px" },
                      on: { confirm: _vm.exportData },
                      scopedSlots: _vm._u([
                        {
                          key: "content",
                          fn: function(ref) {
                            var options = ref.options
                            return [
                              options.item && options.item.name
                                ? _c("div", [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#666" } },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\tYour backup\n\t\t\t\t\t\t\t"
                                        ),
                                        _c(
                                          "span",
                                          {
                                            style: {
                                              color: _vm.$vuetify.theme.primary
                                            }
                                          },
                                          [_vm._v(_vm._s(options.item.name))]
                                        ),
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\twill be transferred to a file server. It may take 15-30 minutes. We will send you an email with the backup download link once it's finished. Or you\n\t\t\t\t\t\t\tcan come back later to download your backup.\n\t\t\t\t\t\t"
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v("Export")
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }