<template>
    <v-container class="panel-wrapper panel-overview pa-0" grid-list-lg fluid >
        <v-layout column>
            <v-flex>
                <TabCard title="Snapshots">
                    <v-layout column>
                        <v-flex>
                            <p style="font-size: 14px;color: #666;margin: 0;" class="fs-3 font-weight-regular ">
                                A snapshot is a filesystem-level backup. It contains a complete copy of the {{ appInfo.product_name }} deployment.
                            </p>
                        </v-flex>
                    </v-layout>
                    <v-layout column>
                        <v-flex>
                            <v-data-table :headers="headers" :items="list" :loading="loading" hide-actions class="elevation-1">
                                <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
                                <template v-slot:items="{ item }">
                                    <td class="text-xs-left">{{ item.name }}</td>
                                    <template v-if="isPending(item.status)">
                                        <td colspan="6" class="text-xs-right px-2"><BackupProcess :item="item" @success="getList" snapshot></BackupProcess></td>
                                    </template>
                                    <template v-else>
                                        <td class="text-xs-right">{{ item.type }}</td>
                                        <td class="text-xs-right">{{ formatDate(item.created) }}</td>
                                        <td class="text-xs-right">{{ item.backup_method === 'Full' ? 'Full' : 'Incremental' }}</td>
                                        <td class="text-xs-right">{{ item.file_size ? `${item.file_size}MB` : '--' }}</td>
                                        <td class="text-xs-right">{{ item.status }}</td>
                                        <td class="text-xs-center px-0">
									<span style="display: inline-block;">
										<template v-if="item.export_status === 'Success'">
											<v-tooltip top>
												<template v-slot:activator="{ on }">
													<v-btn flat icon color="primary" v-on="on" @click="downloadData(item)"><v-icon>cloud_download</v-icon></v-btn>
												</template>
												<span>Download</span>
											</v-tooltip>
										</template>
										<template v-if="item.export_status === 'Processing'">
											<v-tooltip top>
												<template v-slot:activator="{ on }">
													<v-progress-circular v-on="on" indeterminate width="2" size="16" color="primary"></v-progress-circular>
												</template>
												<span>Exporting</span>
											</v-tooltip>
										</template>
									</span>
                                        </td>
                                    </template>
                                    <td class="text-xs-right">
								<span style="display:inline-block;width: 44px;" :title="`Manage your backup.`">
									<v-menu transition="slide-x-transition" v-if="!isPending(item.status)">
										<template v-slot:activator="{ on }">
											<v-btn icon small v-on="on"><v-icon small>more_vert</v-icon></v-btn>
										</template>
										<v-list>
											<v-list-tile :disabled="['Success', 'Restore Failure'].indexOf(item.status) === -1" @click="$refs.restoring.open({ item })">
												<v-list-tile-title>Restore</v-list-tile-title>
											</v-list-tile>
											<v-list-tile
                                                    v-if="item.status === 'Success'"
                                                    :disabled="['Success', 'Processing'].indexOf(item.export_status) !== -1"
                                                    @click="$refs.exporting.open({ item })"
                                            >
												<v-list-tile-title>Export</v-list-tile-title>
											</v-list-tile>
											<v-list-tile @click="$refs.del.open({ item })" v-if="item.type === 'Manual'"><v-list-tile-title>Delete</v-list-tile-title></v-list-tile>
										</v-list>
									</v-menu>
								</span>
                                    </td>
                                </template>
                                <template v-slot:no-data>
                                    <div style="min-height: 192px; position: relative;">
                                        <Spin v-show="loading"></Spin>
                                        <Empty v-show="!loading"></Empty>
                                    </div>
                                </template>
                            </v-data-table>
                        </v-flex>
                        <v-flex style="padding : 30px 0 0;">
                            <v-btn :title="`Take a system-level backup of your application manually.`" class="text-none" color="primary" @click="$refs.addStore.open({ name: createDefaultName() })">Take Snapshot</v-btn>
                        </v-flex>
                    </v-layout>
                    <Confirm ref="del" @confirm="deleteBackup">
                        <span slot="title">Delete Backup</span>
                        <template v-slot:content="{ options }">
					<span v-if="options.item && options.item.name">
						Backup
						<b>{{ options.item.name }}</b>
						will be deleted permanently. Are sure you want to delete it?
					</span>
                        </template>
                    </Confirm>
                    <AddStore ref="addStore" @confirm="addStore"><span>Backup</span></AddStore>
                    <Confirm ref="restoring" @confirm="reStoring" max-width="600px">
                        <span slot="title">Restore Snapshot</span>
                        <template v-slot:content="{ options }">
                            <div v-if="options.item && options.item.name">
						<span style="color: #666;">
							Snapshot
							<span :style="{ color: $vuetify.theme.primary }">{{ options.item.name }}</span>
							will be restored to this {{ appInfo.product_name }} cluster. Its dataset and configuration will be restored to the point when the snapshot was taken.
						</span>
                                <!-- <v-radio-group v-model="selectedRestore" v-if="options.item.site_names.length!==0 && appInfo.product_name === 'WordPress'">
                                    <v-radio v-for="(item,i) in restoreOptions" :key="i" :label="item" :value="item" color="primary"></v-radio>
                                    <v-radio-group v-if="selectedRestore=='Restore By Single Site'" v-model="selectedSingleSite">
                                        <v-radio v-for="(it,idx) in options.item.site_names" :key="idx*2+1" :label="it" :value="it"></v-radio>
                                    </v-radio-group>
                                </v-radio-group> -->
                                <v-subheader class="panel-title">This operation is undoable. Do you want to continue ?</v-subheader>
                            </div>
                        </template>
                    </Confirm>
                    <Confirm ref="exporting" @confirm="exportData" max-width="600px">
                        <span slot="title">Export</span>
                        <template v-slot:content="{ options }">
                            <div v-if="options.item && options.item.name">
						<span style="color: #666;">
							Your backup
							<span :style="{ color: $vuetify.theme.primary }">{{ options.item.name }}</span>
							will be transferred to a file server. It may take 15-30 minutes. We will send you an email with the backup download link once it's finished. Or you
							can come back later to download your backup.
						</span>
                            </div>
                        </template>
                    </Confirm>
                </TabCard>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'; //mapState  mapGetters mapActions
import { findComponentUpward } from '@/module/utils/assist';
import { formatDate } from '@/module/utils/date';
import Empty from '@/components/Empty';
import Spin from '@/components/Spin';
import TabCard from '@/components/TabCard.vue'
import Confirm from '@/components/Confirm.vue';
import AddStore from './dbUser/_source/AddDB';
import BackupProcess from './dbUser/_source/BackupProcess';

export default {
	name: 'Backups',
	components: { Confirm, AddStore, BackupProcess, Spin, Empty, TabCard },
	props: ['databaseDetail'],
	data() {
		return {
			loading: false,
			list: [],
			restoreOptions: ["Restore By All Sites","Restore By Single Site"],			
			selectedRestore:"Restore By All Sites",
			selectedSingleSite:'',
			headers: [
				{ text: 'Name', value: 'name', sortable: false, align: 'left' },
				{ text: 'Type', value: 'type', sortable: false, align: 'right' },
				{ text: 'Created on', value: 'created', sortable: false, align: 'right' },
				{ text: 'Method', value: 'backup_method', sortable: false, align: 'right' },
				{ text: 'Size', value: 'size', sortable: false, align: 'right' },
				{ text: 'Status', value: 'status', sortable: false, align: 'right' },
				{ text: '', value: 'download', sortable: false, align: 'center' },
				{ text: '', value: 'opt', sortable: false, align: 'right' }
			]
		};
	},
	computed: {
		...mapGetters('application', ['getServiceId']),
		...mapState('application', ['appInfo'])
	},
	methods: {
		...mapActions('application', ['getMaskStatus']),
		formatDate,
		isPending(status) {
			return ['Deleting', 'Backuping', 'Restoring'].indexOf(status) !== -1;
		},
		isRestoring() {
			if (!this.comp.showLoading) {
				this.comp.tick();
			}
		},
		createDefaultName() {
			return `snapshot-${this.appInfo.name}-${formatDate(new Date(), 'YYYY-MM-DD-HH-mm-ss')}`;
		},
		exportData({ item }) {
			return this.$http
				.post(`/generic_async_tasks/`, {
					type: 'BackupExport',
					model_name: 'deployments.Backups',
					instance_id: item.id
				})
				.then(() => {
					this.list.forEach(o => {
						if (o.id === item.id) {
							o.export_status = 'Processing';
						}
					});
				})
				.catch(e => {
					this.$message.error(e.detail);
				});
		},
		downloadData(item) {
			this.$http
				.get(`/backups/${item.uuid}/export-url/`)
				.then(res => {
					let link = document.createElement('a');
					link.style.display = 'none';
					link.href = res;
					document.body.appendChild(link);
					link.click();
				})
				.catch(e => {
					this.$message.error(e.detail);
				});
		},
		getList() {
			if (this.loading) {
				return;
			}
			this.loading = true;
			return new Promise((resolve, reject) => {
				this.$http
					.get(`/backups/`, {
						params: {
							service_uuid: this.getServiceId(this.appInfo)
							// type: this.appInfo.category==='Database'?'System':undefined
						}
					})
					.then(res => {
						this.loading = false;
						this.list = res.results.filter(o => o.status !== 'Deleting' && o.status !== 'Failure');
						resolve();
					})
					.catch(() => {
						this.$message.error('Oops, it failed to list your snapshots, please contact us at support@cloudclusters.io for this issue.');
						reject();
					});
			});
		},
		getAllSites(){
			return new Promise((resolve, reject) => {
				this.$http
					.get(`/backups/`, {
						params: {
							service_uuid: this.getServiceId(this.appInfo)
							// type: this.appInfo.category==='Database'?'System':undefined
						}
					})
					.then(res => {
						this.loading = false;
						this.singleSiteOptions = res.results.filter(o => o.status !== 'Deleting' && o.status !== 'Failure');
						resolve();
					})
					.catch(() => {
						this.$message.error('Oops, it failed to list your snapshots, please contact us at support@cloudclusters.io for this issue.');
						reject();
					});
			});
		},
		deleteBackup({ item }) {
			return this.$http
				.delete(`/backups/${item.uuid}`)
				.then(() => {
					this.$message.success(`Backup snapshot-${item.name} was deleted successfully.`);
					this.getList();
				})
				.catch(() => {
					this.$message.error(`Oops, the deletion request was failed to submit, please contact us at support@cloudclusters.io for this issue.`);
				});
		},
		addStore({ name }) {
			return this.$http
				.post('/backups/', {
					name,
					type: 'Manual',
					service_uuid: this.getServiceId(this.appInfo)
				})
				.then(() => {
					this.getList();
					return Promise.resolve();
				})
				.catch(() => {
					this.$message.error('Oops, it failed to create the backup, please contact us at support@cloudclusters.io for this issue.');
				});
		},
		reStoring({ item }) {
			if(this.selectedRestore==="Restore By Single Site"){
				return this.$http
					.post('/restorations/site_restore/', {
						source_backup_uuid: item.uuid,
						restore_method: 'Overwrite',
						site_name: this.selectedSingleSite,
						need_backup: false
					})
					.then(() => {
						this.isRestoring();
						this.getList();
						return Promise.resolve();
					})
					.catch(e => {
						this.$message.error(e.detail);
					});
			}else{
				return this.$http
					.post('/restorations/', {
						source_backup_uuid: item.uuid,
						restore_method: 'Overwrite',
						need_backup: false
					})
					.then(() => {
						this.isRestoring();
						this.getList();
						return Promise.resolve();
					})
					.catch(e => {
						this.$message.error(e.detail);
					});
			}
		}
		
	},
	created() {
		this.comp = findComponentUpward(this, 'ApplicationDetail');
		this.getList();
        this.$SDK.track({pageName:'Snapshots',productName:this.appInfo.product_name})
    },
	mounted() {
		this.comp.$on('mask-over', () => {
			this.getList();
		});
	},
	beforeDestroy() {
		this.comp.$off('mask-over');
	}
};
</script>

<style lang="scss"></style>
